import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Solicitud } from './Modelos/solicitud';
import { MetricasService } from './Services/metricas.service';
declare function obtenerCordenadas():any;
declare function br():any;
declare function origin():any;
declare function cerrarModal(id);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Engie';
  tkn:string='';
  telefono:string='';
  estado:string='';
  constructor(private servicio:MetricasService,private route: ActivatedRoute){
    
    obtenerCordenadas();
    var t = localStorage.getItem('REGSL');
   
    if(t != undefined && t.trim().length>0){
      this.tkn=t;
    }
    
  }
  registrarSolicitud(){
    var navG = br();
    var cords= origin();
    var ori = new Solicitud();
    ori.android_version=navG.name;
    ori.id_application='3';
    ori.model=navG.name+' '+navG.version;
    ori.phone_id = '',
    ori.phone_number = '+521'+this.telefono;
    try{
      ori.other_information={ origin:"QR",lat:cords.latitude,long:cords.longitude, state:this.estado, tel_marcado:'' };
    }catch(ex){      
      ori.other_information={ origin:"",lat:'0',long:'0', state:this.estado, tel_marcado:'' };
    }
    
    this.servicio.registrarInicio(ori).subscribe(s=>{
      if(s.status=="200"){
         localStorage.setItem('REGSL',s.token);
         this.tkn=s.token;
      }
    });
  }

}
