import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Click } from '../Modelos/click';
import { Solicitud } from '../Modelos/solicitud';
import { BaseService } from './base.service';
@Injectable({
  providedIn: 'root'
})
export class MetricasService extends BaseService {

  constructor(protected http: HttpClient) {
    super(http);
    this.pathService = "action";
  }

  registrarInicio(solici:Solicitud){
    return this.postObservable<any>("load", solici);

  }
  registrarClick(click:Click){ 
    this.h = new HttpHeaders({   
    'Access-Control-Allow-Origin': 'https://s10plus.com:8443/wsdemos/rest/',
    'Access-Control-Allow-Headers':'*',
    'Access-Control-Allow-Methods':'*',
    'Content-Type': 'application/json',
    'token': localStorage.getItem('REGSL')
 
});
    return this.postObservable<any>("click", click);
  }
}
