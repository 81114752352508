import { Component, OnInit } from '@angular/core';
declare function showModal(id:string);
@Component({
  selector: 'app-saldo-referencia',
  templateUrl: './saldo-referencia.component.html',
  styleUrls: ['./saldo-referencia.component.scss']
})
export class SaldoReferenciaComponent implements OnInit {
mostarInicio=true;
mostrarRedireccion=false;
  constructor() { }

  ngOnInit(): void {
  }
  pdf(){
    window.open('../../assets/factura_engie.pdf', '_system');
  }
  appEngye(){
    this.mostarInicio=true;
    this.mostrarRedireccion=false;
    showModal('appEngie');
    setTimeout(() => {
      this.mostarInicio=false;
      this.mostrarRedireccion=true;
      setTimeout(()=>{
        window.location.href='pago_recibido';
       
        
      },1000);
    }, 1500);
  }
}
