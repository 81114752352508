<div class="col-12">
    <img src="../../assets/factura.png" class="w-100" alt="">
    <div class="row justify-content-center ">

            <a type="button" class=" btn btn-danger btn-lg col-8 my-2" (click)="pdf()">
                <strong>Estado de Cuenta&nbsp;</strong> 
                <span>
                    <i class="fas fa-file-pdf"></i>
                </span>
            </a>

            <button type="button" class=" btn btn-success btn-lg col-8 my-2" (click)="appEngye()">
                <strong>Pagar&nbsp;</strong> 
                <span style="">
                    <i class="fas fa-cash-register "></i>
                  </span>
            </button>

            <button type="button" class="btn btn-info btn-lg col-8 my-2"  type="button" data-toggle="modal" data-target="#referencias">
                <strong>
                    Referencia&nbsp;
                </strong>
                <span style="">
                    <i class="fas fa-comments-dollar "></i>
                  </span>
            </button>

    </div>
</div>

<!-- Modal Referencias -->
<div class="modal fade" id="referencias" tabindex="-1" aria-labelledby="referenciasLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header headerModal">
          <h5 class="modal-title titleReconexion" id="referenciasLabel">
            <span style="font-size: 3rem;">
                <i class="fas fa-file-invoice-dollar"></i>
            </span>          
            Referencias Bancarias
          </h5>
          
        </div>
        <div class="modal-body modalREconexion">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive-sm">
                        <table class="table table-sm table-hover w-100" style="font-size: 12px !important;">
                            <thead>
                                <tr>
                                  <th scope="col"><strong>Banco</strong></th>
                                  <th scope="col"><strong>CuentaClabe</strong></th>
                                  <th scope="col"><strong>Convenio</strong></th>
                                  <th scope="col"><strong>Referencia</strong></th>
                                </tr>
                              </thead>
                              <tbody>
                                  <tr>
                                      <td>BBVA BANCOMER</td>
                                      <td>012180001480097676</td>
                                      <td>084704</td>
                                      <td>50001032614001874434 </td>
                                  </tr>
                                  <tr>
                                    <td>BANAMEX</td>
                                    <td>002180024641659277</td>
                                    <td>057419</td>
                                    <td>080050001032612021072100222006 </td>
                                </tr>
                                <tr>
                                    <td>7ELEVEN</td>
                                    <td></td>
                                    <td></td>
                                    <td>080050001032612021072100222006 </td>
                                </tr>
                                <tr>
                                    <td>BANCO A.ZTECA</td>
                                    <td></td>
                                    <td></td>
                                    <td>080050001032612021072100222006 </td>
                                </tr>
                                <tr>
                                    <td>COMERCIAL</td>
                                    <td></td>
                                    <td></td>
                                    <td>080050001032612021072100222006 </td>
                                </tr>
                                <tr>
                                    <td>ELEKTRA</td>
                                    <td></td>
                                    <td></td>
                                    <td>080050001032612021072100222006 </td>
                                </tr>
                                <tr>
                                    <td>oxxo</td>
                                    <td></td>
                                    <td></td>
                                    <td>080050001032612021072100222006 </td>
                                </tr>
                                <tr>
                                    <td>SORIANA</td>
                                    <td></td>
                                    <td></td>
                                    <td>080050001032612021072100222006 </td>
                                </tr>
                              </tbody>
                        </table>
                      </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button id="cerrarModalSearch" type="button" class="btn btn-info" data-dismiss="modal">Cerrar</button>          
        </div>
      </div>
    </div>
  </div>
  <!-- Modal App -->
<div class="modal fade" id="appEngie" tabindex="-1" aria-labelledby="appEngieLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header headerModal">
          <h5 class="modal-title titleReconexion" id="appEngieLabel">
            <span style="font-size: 3rem;">
                <i class="fas fa-receipt"></i>
            </span>          
            Procesando servicio de pago
          </h5>
          
        </div>
        <div class="modal-body modalREconexion">
            <div  class="text-center py-2" *ngIf="mostarInicio">
                <h2> Buscando aplicaci&oacute;n <strong>EngiePay</strong></h2>
                <p>
                 espere por favor..
                </p>
                <div class="spinner-grow" role="status">          
                  <span class="sr-only"></span>
                </div>
            </div>
            <div  class="text-center py-2" *ngIf="mostrarRedireccion">
                <h2> No tienes instalada la aplicación <strong>EngiePay</strong></h2>
                <p>
                 redirigiendo al portal de pagos...
                </p>
                <div class="spinner-grow" role="status">          
                  <span class="sr-only"></span>
                </div>
            </div>
        </div>

      </div>
    </div>
  </div>
  