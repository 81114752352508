export class Solicitud {
    public android_version:string="";
    public id_application:string="";
    public model:string="";
    public other_information:Origen;
    public phone_id:string="";
    public phone_number:string="";
}
export class Origen{
    public origin:string="";
    public lat:string="";
    public long:string="";
    public state:string="";
    public tel_marcado:string="";
}