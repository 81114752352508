import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { Click } from '../Modelos/click';
import { MetricasService } from '../Services/metricas.service';
declare function cerrarModal(id:string);
declare function showModal(modal:string);
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  mostrar=false;
  error=false;
  success=false;
  loading=false;
  intentos=0;
  rastreo='';
  referencia='';
  archivo='';
  folio='';
  constructor(config: NgbCarouselConfig,
              private modalService: NgbModal,private servicio:MetricasService
  ) {
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
    var d = new Date();
    //8 a 22
    switch(d.getDay()){
      case 0:
        this.mostrar=false;
        break;
      case 6:
        this.mostrar=!(d.getHours()>=9 && (d.getHours()<=13 && d.getMinutes() <= 59));
        break;
      default:
        this.mostrar=!(d.getHours()>=8 && (d.getHours()<=21 && d.getMinutes() <= 59));
        break;
    }

  }

  ngOnInit(): void {
  }

  sendAnalitycs(id,modalId){
    let ck:Click = new Click();

    switch(id){
      case 203:
        ck.id_action = id;
        ck.other_information = 'Saldo y referencias';  
      break;
      case 205:
        ck.id_action = id;
        ck.other_information = 'Notificación y reconexión';  
      break;
     
    }
     this.servicio.registrarClick(ck).subscribe(()=>{
        showModal(modalId);
     },()=>{});
  }
openUrl(url,id){
  let ck:Click = new Click();

switch(id){
  case 202:
    ck.id_action = id;
    ck.other_information = 'Contratar Servicio';  
  break;
  case 204:
    ck.id_action = id;
    ck.other_information = 'Pago de recibo';  
  break;
  case 211:
    ck.id_action = id;
    ck.other_information = 'Chat';  
  break;
  case 212:
    ck.id_action = id;
    ck.other_information = 'Facebook';  
  break;
  case 213:
    ck.id_action = id;
    ck.other_information = 'LinkedIn';
  break;
  case 214:
    ck.id_action = id;
    ck.other_information = 'YouTube';
  break;
  case 215:
    ck.id_action = id;
    ck.other_information = 'Instagram';
  break;
}
 this.servicio.registrarClick(ck).subscribe(()=>{
    window.location.href=url;
 },()=>{});
  
}
  openCall(tel:string,id): void {
    let ck:Click = new Click();

    switch(id){
      case 207:
        ck.id_action = id;
        ck.other_information = 'Reporte Fuga';  
      break;
      case 208:
        ck.id_action = id;
        ck.other_information = 'Asistencia del hogar';
      break;
      case 209:
        ck.id_action = id;
        ck.other_information = 'Información del servicio';
      break;
      case 210:
        ck.id_action = id;
        ck.other_information = 'Aclaraciones u otros servicios';
      break;
    }
    
   this.servicio.registrarClick(ck).subscribe(()=>{
      window.open('tel:'+tel, '_system');
   },()=>{});
  }
  openMaps(id): void {
   this.servicio.registrarClick({ id_action:id,other_information:'Sucursal más cercana' }).subscribe(()=>{
      window.open('https://www.google.com/maps/place/Engie/@19.5215991,-99.0852304,12z/data=!3m1!5s0x85d21d6f2412a4bb:0xd4b678c5741cc657!4m9!1m2!2m1!1sengie!3m5!1s0x85d21d6f16ba7881:0x61fad34154ac30d4!8m2!3d19.5554509!4d-99.2027969!15sCgVlbmdpZSIDiAEBkgEQY29ycG9yYXRlX29mZmljZQ', '_system');
   },()=>{});    
  }

  enviarReconecion(){  
      if(this.referencia=='' && this.rastreo == '' && this.archivo == ''){
        this.error = true;
        this.intentos++;
        if(this.intentos == 3){
          cerrarModal('cerrarModalReconexion');
        }
      }else{
        this.error = false;
        this.loading = true;
        this.intentos=0;
        setTimeout(() => {
          this.loading = false;
          this.success = true;
          setTimeout(() => {
            cerrarModal('cerrarModalReconexion');
            this.success=false;
            this.referencia==''; this.rastreo == ''; this.archivo = '';
          }, 700);
        }, 1500);                
      }
    
  }
  searchFolio(){
    if(this.folio != '5001237623'){
      this.error= true;
      this.intentos++;
        if(this.intentos == 3){
          cerrarModal('cerrarModalSearch');
        }
    }else{
      this.intentos=0;
      this.error= false;
      this.loading=true;
      setTimeout(() => {
        this.loading=false;
        this.success=true;
        setTimeout(() => {
          cerrarModal('cerrarModalSearch');
          window.location.href='/saldo_referencia';
          this.success=false;
          this.folio='';
        }, 2200);
      }, 1500);
    }
  }
}
