import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contratar',
  templateUrl: './contratar.component.html',
  styleUrls: ['./contratar.component.scss']
})
export class ContratarComponent implements OnInit {

  constructor(private sanitizer:DomSanitizer){}

  ngOnInit(): void {
  }
  url(){
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.engiemexico.com/solicitudservicio');
  }
}
