<div class=" p-4" *ngIf="mostrar" >
  <span>
    Estimado usuario, nuestro horario de atención es de lunes a viernes de 08:00-20:00 hrs y sábados de 09:00-14:00 hrs.
  </span>
</div>
  <div class="row pt-2 pb-0 px-4 justify-content-center buttonsClass" >
    <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xl-4 py-0  text-center cursorPinter"  (click)="openUrl('/contratar_servicio',202)" >
      <img class=" img-fluid rounded p-3 engie-img" src="../../assets/servicio.png"
           alt="Card image cap" >
      <p class="mt-1"> Contratar Servicio</p>

    </div>
    <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xl-4 py-0 text-center cursorPinter" type="button" data-toggle="modal"  (click)="sendAnalitycs(203,'searchFolio')"  >
      <img class="img-fluid rounded p-3 engie-img" src="../../assets/saldos.png"
           alt="Card image cap"
      >
      <p class="mt-1">Saldo y Referencias</p>

    </div>
    <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xl-4 py-0 text-center cursorPinter" (click)="openUrl('/pago_recibido',204)"  >
      <img class="img-fluid rounded p-3 engie-img" src="../../assets/recibo.png"
           alt="Card image cap"
      >
      <p class="mt-1">Pago de recibo</p>

    </div>
    <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xl-4 py-0  text-center cursorPinter"  type="button" data-toggle="modal"  (click)="sendAnalitycs(205,'exampleModal')" >
      <img class=" img-fluid rounded p-3 engie-img" src="../../assets/reconexion.png"
           alt="Card image cap" >
      <p class="mt-1"> Notificaci&oacute;n y Reconexi&oacute;n</p>

    </div>
    <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xl-4 py-0 text-center cursorPinter" (click)="openMaps(206)" >
      <img class="img-fluid rounded p-3 engie-img" src="../../assets/sucursal.png"
           alt="Card image cap"
      >
      <p class="mt-1">Sucursal m&aacute;s Cercana</p>

    </div>
    <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xl-4 py-0 text-center cursorPinter"  (click)="openCall('8009099999',207)" >
      <img class="img-fluid rounded p-3 engie-img" src="../../assets/fuga.png"
           alt="Card image cap"
      >
      <p class="mt-1">Reporte Fuga</p>

    </div>
    <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xl-4 py-0  text-center cursorPinter"  (click)="openCall('8005608738',208)">
      <img class=" img-fluid rounded p-3 engie-img" src="../../assets/Icono_Asistencia en el Hogar.png"
           alt="Card image cap" >
      <p class="mt-1"> Asistencia del hogar</p>

    </div>
    <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xl-4 py-0 text-center cursorPinter"  (click)="openCall('8000006294',209)" >
      <img class="img-fluid rounded p-3 engie-img" src="../../assets/informacion servicio.png"
           alt="Card image cap"
      >
      <p class="mt-1">Información de Servicio</p>

    </div>
    <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xl-4 py-0 text-center cursorPinter"  (click)="openCall('8000006294',210)" >
      <img class="img-fluid rounded p-3 engie-img" src="../../assets/aclaracion.png"
           alt="Card image cap"
      >
      <p class="mt-1">Aclaraciones u otros Servicios</p>

    </div>
    <div class="col-4 col-lg-4 col-md-4 col-sm-4 col-xl-4 py-0 text-center cursorPinter" (click)="openUrl('https://demoengieamad.s10plus.com/botengie.html',211)"   >
      <img class="img-fluid rounded p-3 engie-img" src="../../assets/chat.png"
           alt="Card image cap"
      >
      <p class="mt-1">Chat</p>

    </div>
  </div>

 
<div class="row justify-content-center redesSociales py-1">
  <div class="col-2 ">
    <a (click)="openUrl('https://www.facebook.com/ENGIEMexico',212)" target="blanlk" >
      <span>
        <i class="fab fa-facebook-f"></i>
      </span>
    </a>
  </div>
  <div class="col-2">
    <a (click)="openUrl('https://mx.linkedin.com/company/engiemexico/',213)" target="blanlk">
      <span>
        <i class="fab fa-linkedin-in"></i>
      </span>
    </a>
  </div>
  <div class="col-2">
    <a (click)="openUrl('https://www.youtube.com/channel/UCMIwCRIa7X9euKpmL2Q3MqQ',214)" target="blanlk">
      <span>
        <i class="fab fa-youtube"></i>
      </span>
    </a>
  </div>
  <div class="col-2">
    <a (click)="openUrl('https://www.instagram.com/engiemexico/?hl=es-la',215)" target="blanlk">
      <span>
        <i class="fab fa-instagram"></i>
      </span>
    </a>
  </div>
</div>
  <ngb-carousel  style="height:190px;" >
    <ng-template ngbSlide>
      <div class="wrapper ">
         <a href="https://market.engiemx.com/" target="blanlk" >
          <img src="../../assets/marketplace.jpg" class="" style="width: 100%"  height="190px" alt="Random first slide">
         </a>
      </div>      
    </ng-template>
    <ng-template ngbSlide>
      <div class="wrapper ">
        <a href="https://www.engiemexico.com/" target="blanlk" >
          <img src="../../assets/portal.jpg" class="" style="width: 100%"  height="190px" alt="Random first slide">
         </a>
      </div>      
    </ng-template>
    <ng-template ngbSlide>
      <div class="wrapper">
        <a href="https://qrco.de/bbplpp" target="blanlk" >
          <img src="../../assets/app.jpg" class="" style="width: 100%"  height="190px" alt="Random first slide">
         </a>
      </div>      
    </ng-template>
  </ngb-carousel>
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header headerModal">
        <h5 class="modal-title titleReconexion" id="exampleModalLabel">
          <span style="font-size: 3rem;">
            <i class="fas fa-retweet fas-4x"></i>
          </span>          
          Reconexi&oacute;n/Notificaci&oacute;n
        </h5>
        
      </div>
      <div class="modal-body modalREconexion">
        <div *ngIf="success" class="alert alert-success" role="alert">
          Solicitud enviada, en 30 min tu servicio ser&aacute; reestablecido o un asesor se comunicara contigo.
        </div>
        <div *ngIf="error" class="alert alert-danger" role="alert">
          Por favor proporciona alguna de las 3 opciones, gracias.
        </div>
        <div *ngIf="loading" class="text-center py-2">
          <p>
            Enviando informaci&oacute;n, espere por favor...
          </p>
          <div class="spinner-grow" role="status">          
            <span class="sr-only"></span>
          </div>
        </div>
        
        <div class="form-group">
          <label for="exampleInputEmail1">Ingresa tu <strong>clave de rastreo</strong></label>
          <input type="text" class="form-control" id="exampleInputEmail1"  [(ngModel)]="rastreo" aria-describedby="emailHelp">
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Ingresa tu <strong>referencia</strong></label>
          <input type="text" class="form-control" id="exampleInputEmail1" [(ngModel)]="referencia" aria-describedby="emailHelp">
        </div>
        <div class="form-group">
          <label for="exampleInputEmail1">Comprobante de <strong>Pago</strong></label>
          <input type="file" class="form-control" id="exampleInputEmail1" [(ngModel)]="archivo" aria-describedby="emailHelp">
        </div>
      </div>
      <div class="modal-footer">
        <button id="cerrarModalReconexion" type="button" class="btn btn-info" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)=" enviarReconecion()">Enviar</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal Folio -->
<div class="modal fade" id="searchFolio" tabindex="-1" aria-labelledby="searchFolioLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header headerModal">
        <h5 class="modal-title titleReconexion" id="searchFolioLabel">
          <span style="font-size: 3rem;">
            <i class="fas fa-search fas-4x"></i>
          </span>          
          Buscar cuenta/contrato
        </h5>
        
      </div>
      <div class="modal-body modalREconexion">
       
        <div *ngIf="loading" class="text-center py-2">
          <p>
            Enviando informaci&oacute;n, espere por favor...
          </p>
          <div class="spinner-grow" role="status">          
            <span class="sr-only"></span>
          </div>
        </div>
        
        <div class="form-group">
          <label for="exampleInputEmail1">Ingresa tu n&uacute;mero de <strong>cuenta/contrato</strong></label>
          <input type="text" class="form-control" id="exampleInputEmail1"  [(ngModel)]="folio" aria-describedby="emailHelp">
        </div>
        <div *ngIf="success" class="alert alert-success" role="alert">
          cuenta/contrato encontrado, espere cargando informaci&oacute;n.
        </div>
        <div *ngIf="error" class="alert alert-danger" role="alert">
          cuenta/contrato no valido, int&eacute;ntalo de nuevo
        </div>
      </div>
      <div class="modal-footer">
        <button id="cerrarModalSearch" type="button" class="btn btn-info" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)=" searchFolio()">Buscar</button>
      </div>
    </div>
  </div>
</div>
