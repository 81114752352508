import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChatComponent } from './chat/chat.component';
import { ContratarComponent } from './contratar/contratar.component';
import {MainComponent} from './main/main.component';
import { PagoRecibidoComponent } from './pago-recibido/pago-recibido.component';
import { SaldoReferenciaComponent } from './saldo-referencia/saldo-referencia.component';

const routes: Routes = [

  { path: '', redirectTo: 'home', pathMatch: 'full' },

  {path: 'home', component: MainComponent},
  {path:'contratar_servicio', component:ContratarComponent},
  {path:'pago_recibido', component:PagoRecibidoComponent},
  {path:'saldo_referencia', component:SaldoReferenciaComponent},
  {path:'chat', component:ChatComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
