<div class="container engie-general-config" >
    <nav class="navbar  engie-toolbar w-100 "   >
        <div class="row w-100 m-0">
            <div class="col-2">               
            </div>
            <div class="col-8 d-flex align-items-center justify-content-center">
                <div class="text-center">
                    <img src="../assets/logo.png" alt="">
                    <p class="">Adminisitra tus servicios de Gas Natural</p>
                </div>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
                <a href="/" type="button" class="btn align-content-end"   style="color: white">Menu</a>
            </div>
        </div>
    </nav>
    <div class="row contenido">
        <router-outlet ></router-outlet>
    </div>
    <footer class="row p-2 engie-footer">
        <span class="w-100">Powered by S10 Plus ® - 2022 - All Rights Reserved</span>
    </footer>
</div>
<!-- Modal -->
<div *ngIf="tkn == ''" class="modal fade show" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" 
tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style="display: block; background-color: rgba(128,128,128,0.5);">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> -->
      <div class="modal-body text-center">
        <div class="row">
            <div class="col-sm-12 col-md-12">
              <span>
                  Hola, para mejorar tu experiencia con la aplicación, apóyanos con tu número de teléfono, Gracias
                </span>
                <div class="row text-left">                      
                  <div class= "col-12 mb-3">
                      <label for="exampleFormControlInput1" class="form-label">Teléfono</label>
                      <input type="text" class="form-control" id="exampleFormControlInput1" [(ngModel)]="telefono" value="{{telefono}}">
                  </div>
                  <div class="col-12 mb-3">
                     <label for="selEdo" class="form-label">¿De dónde nos visitas?</label>
                      <select id="selEdo" class="form-control" aria-label="Default select example" [(ngModel)]="estado">                            
                        <option value="CDMX" >Ciudad de México</option>
                        <option value="MEX" >Estado de México</option>
                        <option value="JAL" >Jalisco</option>
                        <option value="PUE" >Puebla</option>
                        <option value="QRO" >Querétaro</option>
                        <option value="TAMPS" >Tamaulipas</option>
                        <option value="TLAX" >Tlaxcala</option>
                       
                        </select>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer">          
          <button type="button" class="btn btn-primary"  (click)="registrarSolicitud()" *ngIf="telefono != '' && telefono.length>=10 && estado != '' " >Aceptar</button>
      </div>
    </div>
  </div>
</div>